import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import SignTool from "./util/SignTool.js";

import utils from "./utils/utils";

Vue.prototype.$utils = utils;

// 导入全局样式
import "@/assets/css/global.css";
// ---------------网络配置-------------------
import axios from "axios";
import qs from "qs";
// 配置请求根路径
// 本机地址
// axios.defaults.baseURL = "http://192.168.0.147:8000/im-tenant/";
// axios.defaults.baseURL = "http://192.168.0.195:8000/im-tenant/";
// axios.defaults.baseURL = "http://47.244.15.43:8000/";
axios.defaults.baseURL = "https://api.cash940.com/im-tenant/";
  //  axios.defaults.baseURL = "http://59.36.24.138:10580/api/im-tenant/"; // 测试环境

//全局全屏载入loading
let loadingInstance = null;

// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use((config) => {
  //实际用的还是element ui的
  loadingInstance = Vue.prototype.$loading({
    fullscreen: true,
    Lock: true,
    text: "拼命加载中",
  });

  // 如果是登录请求
  if (config.url === "Connect/token") {
    // 改默认json为form请求
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded;charset=utf-8";
    config.data = qs.stringify(config.data);
  }
  // else if(config.url === 'platform-file/api/FileUpload'){
  //   // 为请求头对象，添加token验证的Authorization字段
  //   config.headers.Authorization = window.sessionStorage.getItem('token')
  // }
  else {
    // 改为json
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    // 为请求头对象，添加token验证的Authorization字段
    config.headers.Authorization = window.sessionStorage.getItem("token");

    var timestamp = new Date().getTime().toString();
    var nonce = SignTool.randomStr(8);
    var sign = SignTool.CreateSign(timestamp, nonce, config);
    config.headers["timestamp"] = timestamp;
    config.headers["nonce"] = nonce;
    config.headers["sign"] = sign;
  }

  // 打印请求信息
  // console.log('请求拦截器：', config)

  // 在最后必须 return config
  return config;
});

// --------------response 拦截器中---------------------------
//是否有请求正在刷新token
let isRefreshing = false;
// 重试请求队列 每一项都是一个待执行待函数
let requests = [];
axios.interceptors.response.use(
  function (response) {
    // console.log('响应拦截器：', response)
    loadingInstance.close();
    return response;
  },
  function (error) {
    loadingInstance.close();
    // 在这里我们要对别人的错误状态码进行判断， 来使用刷新token 解决token失效 也就是401的问题； 让别人在后台 看不见的地方，登录以失败，然后里面调用这里的请求，在获取最新的token值，然后重新设置到我们的 本地储存里面去
    // console.log('响应拦截器的错误日志：', error.response)
    // ===判断401就刷新token===
    if (error.response && error.response.status === 401) {
      // token过期，跳到登录页面，并清空token、school_id、school_name
      // window.sessionStorage.clear()
      // router.push('/login')
      if (!isRefreshing) {
        isRefreshing = true;

        return axios
          .post(
            "Connect/token",
            {
              grant_type: "refresh_token",
              refresh_token: window.sessionStorage.getItem("refresh_token"),
              client_id: "platform",
              client_secret: "12345678",
            },
            {
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=utf-8",
              },
            }
          )
          .then((res) => {
            //保存新token
            window.sessionStorage.setItem(
              "token",
              "Bearer " + res.data.access_token
            );
            window.sessionStorage.setItem(
              "refresh_token",
              res.data.refresh_token
            );

            // console.log("刷新token==>", res.data.access_token)

            error.config.headers.Authorization =
              "Bearer " + res.data.access_token;

            requests.length > 0 &&
              requests.map((cb) => {
                cb();
              });
            requests = [];

            return axios.request(error.config);
          })
          .catch((err) => {
            console.log("刷新验证码错误时，直接跳到登录", err);
            window.sessionStorage.clear();
            router.push("/login");
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        return new Promise((resolve) => {
          requests.push(() => {
            error.config.headers.Authorization =
              window.sessionStorage.getItem("token");
            resolve(axios.request(error.config));
          });
        });
      }
    } else if (error.response.status === 403) {
      window.sessionStorage.clear();
      router.push("/login");
    } else if (error.response.status === 400) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code == -1
      ) {
        //密码错误或验证码过期时的提示要返回
        return error.response;
      } else {
        //bad request 错误的请求
        // console.log("错误码，调到400错误页面==========>", error.response.status)
      }
    } else {
      
      // console.log("错误码==========>", error.response.status)
    }

    return Promise.reject(error);
  }
);

// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios;

// -------------配置路由，显示标题-------------
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
router.afterEach((to) => {
  window.sessionStorage.setItem("activePath", to.path);
});
// -------------日期格式化----------------
Date.prototype.format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};
// ----------------------------------

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
