<template>
    <div class="component-container">
      <div class="iconScroll_row">
        <div class="form-atta row iconScroll">
          <div class="iconScroll-top">
            <div class="atta-scroll" :style="{ overflowX: 'auto' }">
              <div class="upload-image row">
                <div
                  class="image-li"
                  v-for="(item, index) in uploadPathList"
                  :key="index"
                >
                  <div
                    class="icon"
                    @click="previewImage(item)"
                    v-if="item.state === 1"
                    >
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="item.path"
                      :preview-src-list="srcList"
                      class="show-image-cash"
                    >
                    </el-image>
                    
                  </div>
                  <div
                    class="icon"
                    @click="retryDownload(item)"
                    v-if="item.state === -1"
                  >
                    <div style="width: 100px; height: 100px; font-size: 14px">
                      加载失败
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'TransactionImageUpload', 
    props: {
      uploaded:{},
    },
    data() {
      return {
        uploadPathList: [], // 存储文件状态和路径
        srcList: [],
      };
    },
    watch: {
      uploaded: {
        immediate: true,
        handler(newVal) {
       
          let fileList = Array.isArray(newVal) ? newVal : newVal.split(",");
          
           
     
          this.uploadPathList = fileList.map((fileKey) => ({
       
            fileKey,
         
            state: 0,
         
            path: "",
          }));
          console.log(this.uploadPathList,fileList);
          
          // 调用下载文件的方法
          this.downloadFiles();
        },
      },
    },
    methods: {

      
      async downloadFiles() {
        for (const item of this.uploadPathList) {
          try {
            const response = await this.$http.get("FileUpload/GetBase64", {
              params: { filekey: item.fileKey },
            });
  
            // 解析 base64 字符串并转换为 Blob 对象
            const binaryData = atob(
              response.data.replace(/^data:image\/\w+;base64,/, "")
            );
            const arrayBuffer = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
              arrayBuffer[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer], { type: "image/jpeg" }); // 根据实际图片格式调整 MIME 类型
  
            // 生成一个 URL 来显示图片
            item.path = URL.createObjectURL(blob);
            item.state = 1;
          } catch (error) {
            console.error("Error downloading the file:", error);
            item.state = -1;
          }
        }
      },
      previewImage(item) {
        // 实现预览逻辑
        console.log("预览图片:", item.path);
          
  
        this.srcList.push(item.path);
       
      },
  
      retryDownload(item) {
        // 重新尝试下载
        this.downloadFile(item.fileKey)
          .then(() => {
            item.state = 1;
          })
          .catch(() => {
            item.state = -1;
          });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .component-container {
    width: 100%;
    height: 100%;
  }
  
  .iconScroll_row {
    width: 100%;
    height: 100%;
    .iconScroll {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      .iconScroll-top {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .form-name {
        width: unset;
        min-width: 50px;
        max-width: 100px;
        font-size: 16px;
      }
      .atta-scroll {
        width: calc(100% - 100px);
        padding-left: 0;
        height: 100%;
      }
    }
  }
  
  .upload-image {
    height: 100%;
    width: 100%;
    align-items: center;
    padding-top: 6rpx;
  
    .image-li {
      position: relative;
      margin-right: 10rpx;
      align-items: flex-end;
      .icon {
        view {
          display: flex;
          flex-direction: row-reverse;
    
          
        }
      }
      .image-icon {
        position: absolute;
        top: -6rpx;
        margin: auto;
        left: 0;
        right: 0;
        font-size: 36rpx;
        width: 36rpx;
      }
    }
  }
  
  .show-image-cash {
    width: 100px;
    height: 100px;
  }
  </style>
  