<template>
    <div class="transactionAccounting">
        <!-- 面包屑导航区 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>统计</el-breadcrumb-item>
            <el-breadcrumb-item>往来记账</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-form label-width="80px" :model="formLabelAlign">
                <el-row>
                    <el-col :span="5">
                        <el-form-item label="开始时间:">
                            <el-date-picker v-model="formLabelAlign.startime" type="date" @change="handleDateChangest" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="结束时间:">
                            <el-date-picker v-model="formLabelAlign.endtime" @change="handleDateChangeet" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="商家:">
                            <el-select v-model="formLabelAlign.tenantPopover" @change="_getstoreData" placeholder="请选择">
                                <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenant_name"
                                    :value="item.tenant_id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="选择店铺:">
                            <el-select @change="handleStoreChange" v-model="formLabelAlign.storeID" collapse-tags
                                placeholder="请选择">
                                <el-option v-for="item in storeList" :key="item.value" :label="item.store_name"
                                    :value="item.store_id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="选择币种:">
                  <el-select clearable v-model="formLabelAlign.currencyID" placeholder="请选择">
                    <el-option v-for="(item, index) in bizhongshujuList" :key="index" :label="item.currency_name"
                      :value="item.currency_id"></el-option>
                  </el-select>
                </el-form-item>
                </el-col>
               
                </el-row>
                <el-row>
                    <el-col :span="3">
                        <el-form-item label="不同店铺:">
                            <el-checkbox border style="width: 100% !important; height: 100%;" v-model="formLabelAlign.differentStore"></el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-button type="primary"  @click="_getsousuo" icon="el-icon-search">搜索</el-button>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>
        <el-card style="margin-top: 20px;">

            <el-tabs v-model="activeName" >
    <el-tab-pane label="发布交易单欠其他店,对方对数收款给我" name="first">
        <el-button @click="firstexportToExcel">导出</el-button>
        <el-table :data="tableDatafirst" style="width: 100%;">
            <el-table-column label="流水号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="账户店铺" prop="account_store_name"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_account_flow_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="交易类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" width="70px" prop="exchange_hide_flag">
                <template slot-scope="scope">
                    <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                    <span v-else>否</span>
                </template>
            </el-table-column>
            <el-table-column label="交易单的店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="交易单的客户名字" prop="store_exchange_archives_name">
                <template slot-scope="scope">
                    <span>{{ scope.row.store_exchange_archives_name }} ({{  scope.row.store_exchange_archives_no  }})</span>
                </template>
            </el-table-column>
            <el-table-column label="交易单详情金额" prop="store_exchange_detail_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_exchange_detail_amount) }}</span>
               </template>
            </el-table-column>
            <el-table-column label="交易单详情的币种" prop="store_exchange_detail_currency_name"></el-table-column>
            <el-table-column label="对手的收款单号" prop="store_receipt_no"></el-table-column>
            <el-table-column label="对手的收款单状态" prop="store_receipt_status_name"></el-table-column>
            <el-table-column label="对手的收款店铺" prop="store_receipt_store_name"></el-table-column>
            <el-table-column label="对手的收款单的客户名字" prop="store_receipt_archives_name">
                <template slot-scope="scope">
                    <span>{{ scope.row.store_receipt_archives_name }} ({{  scope.row.store_receipt_archives_no  }})</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的收款单的金额" prop="store_receipt_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_receipt_amount) }}</span>
                </template>
            </el-table-column>


        </el-table>
        <el-pagination  :page-size="pageinfofirst.pageSize" layout="prev, pager, next"  :total="pageinfofirst.total" @current-change="firsthandleCurrentChange">
        </el-pagination>
    </el-tab-pane>
   

    <el-tab-pane label="发布交易单欠其他店,对方店铺账户付款给我" name="second">
        <el-button @click="secondexportToExcel">导出</el-button>
        <el-table :data="tableDatasecond" style="width: 100%;">
            <el-table-column label="流水号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="账户店铺" prop="account_store_name"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_account_flow_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="交易类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" prop="exchange_hide_flag">
                <template slot-scope="scope">
                    <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                    <span v-else>否</span>
                </template>
            </el-table-column>
            <el-table-column label="交易单的店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="交易单的客户名字" prop="store_exchange_archives_name">
                <template slot-scope="scope">
                    <span>{{ scope.row.store_exchange_archives_name }} ({{  scope.row.store_exchange_archives_no  }})</span>
                </template>
            </el-table-column>
            <el-table-column label="交易单详情金额" prop="store_exchange_detail_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_exchange_detail_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="交易单详情的币种" prop="store_exchange_detail_currency_name"></el-table-column>
            <el-table-column label="对手的付款单号" prop="store_payment_no"></el-table-column>
            <el-table-column label="对手的付款单状态" prop="store_payment_status_name"></el-table-column>
            <el-table-column label="对手的付款单店铺" prop="store_payment_store_name"></el-table-column>
            <el-table-column label="对手的付款单的金额" prop="store_payment_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_payment_amount) }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination  :page-size="pageinfosecond.pageSize" layout="prev, pager, next"  :total="pageinfosecond.total" @current-change="secondhandleCurrentChange">
        </el-pagination>
    </el-tab-pane>
    <el-tab-pane label="制“其他客户”收款单,还其他店" name="third">
        <el-button @click="thirdexportToExcel">导出</el-button>
        <el-table :data="tableDatathird" style="width: 100%;">
            <el-table-column label="流水号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_account_flow_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="收款单号" prop="store_receipt_no"></el-table-column>
            <el-table-column label="收款单店名" prop="store_receipt_store_name"></el-table-column>
            <el-table-column label="收款单客户名字" prop="store_receipt_archives_name">
                <template slot-scope="scope">
                    <span>{{ scope.row.store_receipt_archives_name }} ({{  scope.row.store_receipt_archives_no  }})</span>
                </template>
            </el-table-column>
            <el-table-column label="收款单金额" prop="store_receipt_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_receipt_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="对手的交易单类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="对手的交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" prop="exchange_hide_flag">
                <template slot-scope="scope">
                    <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                    <span v-else>否</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的交易单店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="对手的交易单详情金额" prop="store_exchange_detail_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_exchange_detail_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的交易单客户名字" prop="store_exchange_archives_name">
                <template slot-scope="scope">
            <span>
              {{ scope.row.store_exchange_archives_name }} ({{  scope.row.store_exchange_archives_no  }})
            </span>
          </template>
            </el-table-column>
        </el-table>
        <el-pagination  :page-size="pageinfothird.pageSize" layout="prev, pager, next"  :total="pageinfothird.total" @current-change="thirdhandleCurrentChange">
        </el-pagination>
        </el-tab-pane>
    <el-tab-pane label="制“其他客户”的店铺的收款单,还其他店" name="fourth">
        <el-button @click="fourthexportToExcel">导出</el-button>
        <el-table :data="tableDatafourth" style="width: 100%;">
            <el-table-column label="流水编号" prop="store_account_flow_id"></el-table-column>
            <el-table-column label="流水金额" prop="store_account_flow_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_account_flow_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="流水币种" prop="store_account_flow_currency_name"></el-table-column>
            <el-table-column label="付款单号" prop="store_payment_no"></el-table-column>
            <el-table-column label="付款单店铺" prop="store_payment_store_name"></el-table-column>
            <el-table-column label="付款金额" prop="store_payment_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_payment_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的交易单号" prop="store_exchange_no"></el-table-column>
            <el-table-column label="对手的交易单店铺" prop="exchange_store_name"></el-table-column>
            <el-table-column label="对手的交易单类型" prop="exchange_type_name"></el-table-column>
            <el-table-column label="对手的交易单状态" prop="exchange_status_name"></el-table-column>
            <el-table-column label="是否隐藏" prop="exchange_hide_flag">
                <template slot-scope="scope">
                    <span v-if="scope.row.exchange_hide_flag == 1">是</span>
                    <span v-else>否</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的交易单详情金额" prop="store_exchange_detail_amount">
                <template slot-scope="scope">
                    <span>{{ formatAmount( scope.row.store_exchange_detail_amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="对手的交易单客户名字" prop="store_exchange_archives_name">
                <template slot-scope="scope">
                    <span>{{ scope.row.store_exchange_archives_name }} ({{  scope.row.store_exchange_archives_no  }})</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination  :page-size="pageinfofourth.pageSize" layout="prev, pager, next"  :total="pageinfofourth.total" @current-change="fourthhandleCurrentChange">
            </el-pagination>
    </el-tab-pane>
  </el-tabs>
        </el-card>
    </div>
</template>
<script>
import moment from "moment";
import * as XLSX from "xlsx";
export default {
    data() {
        return {
            formLabelAlign: {
                startime: '',
                endtime: '',
                st:"",//开始时间
                et:"",//结束时间
                tenantPopover: '',
                storeID: '', //门店选择
                currencyID: '',//币种
                differentStore: false //是否相同
            },
            tenantList: [], //商家列表
            storeList: [], //门店列表
            bizhongshujuList: [], //币种数据
            activeName: 'first', // 当前激活的tab
            tableDatafirst: [], // 表格数据
            pageinfofirst: {
                pageIndex: 1,
                pageSize: 10,
                pageCount: 0,
                total: 0
            },//first表格分页信息
            
            tableDatasecond: [], // second表格数据
            pageinfosecond: {
                pageIndex: 1,
                pageSize: 10,
                pageCount: 0,
                total: 0
            },//second表格分页信息
            tableDatathird: [], // third表格数据
            pageinfothird: {
                pageIndex: 1,
                pageSize: 10,
                pageCount: 0,
                total: 0
            },//third表格分页信息
            tableDatafourth: [], // fourth表格数据
            pageinfofourth: {
                pageIndex: 1,
                pageSize: 10,
                pageCount: 0,
                total: 0
            },//fourth表格分页信息
            storename:"",//门店名称
        }
    },
    mounted() {
        this.getTenantList(); //获取商家列表
    },
    methods: {
        // 总搜索
        _getsousuo(){
            this.pageinfofirst.pageIndex = 1;
            this._getfirsttable();
            this.pageinfosecond.pageIndex = 1;
            this._getsecondtable();
            this.pageinfothird.pageIndex = 1;
            this._getthirdtable();
            this.pageinfofourth.pageIndex = 1;
            this._getfourthtable();

        },
            //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
        // 修改时间格式
        handleDateChangest(val) {
      if (val) {
            this.formLabelAlign.st = moment(val).format("YYYY-MM-DD") + " 00:00:00" ;
      }   
    },
    handleDateChangeet(val) {
      if (val) {
            this.formLabelAlign.et = moment(val).format("YYYY-MM-DD") + " 23:59:59" ;
      }         
    },
        // 获取商家列表
        getTenantList() {
            const tenantsJson = localStorage.getItem("loginTenantsInfo");
            let tenants = [];
            if (tenantsJson) {
                tenants = JSON.parse(tenantsJson);
                console.log("使用解析后的对象", tenants);
            } else {
                console.log("No tenants data found in local storage.");
            }
            this.tenantList = tenants;
        },
        // 获取门店信息
        _getstoreData() {
            this.storeID = ''
            this.$http.get("v1/Store/GetStoreInfoByTenant", {
                params: { tenant: this.formLabelAlign.tenantPopover },
            }).then(({ data: res }) => {

                this.storeList = res.data;

            });
        },
        // 店铺选择
        handleStoreChange(item) {
            
            // 根据 item 去找看他选择那个店铺
            this.storeList.find((item1) => {
                if (item1.store_id == item) {
                    this.storename = item1.store_name;            
                }
            });
            
            this.$http.get("/StoreCurrency/GetEnable", {
                params: {
                    store: item,
                    tenant: this.formLabelAlign.tenantPopover
                }
            }).then((res) => {
                if (res.code == -1) return this.$message.error(res.message);
                this.bizhongshujuList = res.data.data;

            });
        },
        // 获取first数据
        async _getfirsttable() {
            try {
        const { data: res } = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInReceipt", {
          params: {
            st: this.formLabelAlign.st,
            et: this.formLabelAlign.et,
            storeID: this.formLabelAlign.storeID,
            currencyID: this.formLabelAlign.currencyID,
            differentStore: this.formLabelAlign.differentStore,
            pageIndex: this.pageinfofirst.pageIndex,
            pageSize: this.pageinfofirst.pageSize,
          },
        });
        console.log(res);
        if (res.code !== 1) {
          this.$message.error(res.message);
          return;
        }
        this.tableDatafirst = res.data;
        this.pageinfofirst = res.pageInfo;
      } catch (error) {
        // 这里不会捕获到503错误，因为已经在拦截器中处理了
        this.$message.error('接口报错了');
      }
          },
        //   first表格分页
          firsthandleCurrentChange(val) {
            this.pageinfofirst.pageIndex = val;
            this._getfirsttable();
           },
           //  导出数据
          async firstexportToExcel(){
            const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInReceipt", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: 1,
              pageSize: this.pageinfofirst.total,
            }
          });
          if (res.code !== 1) return this.$message.error(res.message);
          let datefirst = []
         datefirst = res.data.filter(row => row.exchange_hide_flag !== '1'); // 过滤掉需要隐藏的数据
         let date = moment().format("MM-DD HH:mm");

          const ws_name = `${this.storename}-tab1-${date}`;

      const ws_data = [
        [
          "流水号",
          "账户店铺",
          "流水金额",
          "流水币种",
          "交易单号",
          "交易类型",
          "交易单状态",
          "是否隐藏",
          "交易单的店铺",
          "交易单的客户名字",
          "交易单详情金额",
          "交易单详情的币种",
          "对手的收款单号",
          "对手的收款单状态",
          "对手的收款单店铺",
          "对手的收款单的客户名字",
          "对手的收款单的金额"
        ],
        ...datefirst.map(row => [
          row.store_account_flow_id,
          row.account_store_name,
          this.formatAmount(row.store_account_flow_amount),
          row.store_account_flow_currency_name,
          row.store_exchange_no,
          row.exchange_type_name,
          row.exchange_status_name,
          row.exchange_hide_flag == 1 ? "是" : "否",
          row.exchange_store_name,
          `${row.store_exchange_archives_name} (${row.store_exchange_archives_no})`,
          this.formatAmount(row.store_exchange_detail_amount),
          row.store_exchange_detail_currency_name,
          row.store_receipt_no,
          row.store_receipt_status_name,
          row.store_receipt_store_name,
          `${row.store_receipt_archives_name} (${row.store_receipt_archives_no})`,
          this.formatAmount(row.store_receipt_amount)
        ])
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // 保存文件
      this.saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), {
        type: "application/octet-stream",
        headers: { "Content-Disposition": `attachment;filename=${ws_name}.xlsx` }
      });


          },

// ------------------------------------------------------------
        //   获取second数据
        async _getsecondtable() {
            const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInStorePay", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: this.pageinfosecond.pageIndex,
              pageSize: this.pageinfosecond.pageSize,
            }
          });
            if (res.code !== 1) return this.$message.error(res.message);
            this.tableDatasecond = res.data;
            this.pageinfosecond = res.pageInfo;
        },
        //   second表格分页
        secondhandleCurrentChange(val) {
            this.pageinfosecond.pageIndex = val;
            this._getsecondtable();
           },
        //  导出数据
       async secondexportToExcel(){

            const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowInStorePay", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: 1,
              pageSize: this.pageinfosecond.total,
            }
          });
          if (res.code !== 1) return this.$message.error(res.message);
     let datesecond = res.data.filter(row => row.exchange_hide_flag !== '1'); // 过滤掉需要隐藏的数据
        

            //  用moment获取当前时间
            let date = moment().format("MM-DD HH:mm");

                  // 准备导出的数据 
      const ws_name =  `${this.storename}-tab2-${date}`
      const ws_data = [
        ["流水号", "账户店铺", "流水金额", "流水币种", "交易单号", "交易类型", "交易单状态", "是否隐藏", "交易单的店铺", "交易单的客户名字",  "交易单详情金额", "交易单详情的币种", "对手的付款单号", "对手的付款单状态", "对手的付款单店铺", "对手的付款单的金额"],
        ...datesecond.map(row => [
          row.store_account_flow_id,
          row.account_store_name,
          this.formatAmount(row.store_account_flow_amount),
          row.store_account_flow_currency_name,
          row.store_exchange_no,
          row.exchange_type_name,
          row.exchange_status_name,
          row.exchange_hide_flag == 1 ? "是" : "否",
          row.exchange_store_name,
          `${row.store_exchange_archives_name} (${row.store_exchange_archives_no})`,
          
          this.formatAmount(row.store_exchange_detail_amount),
          row.store_exchange_detail_currency_name,
          row.store_payment_no,
          row.store_payment_status_name,
          row.store_payment_store_name,
          this.formatAmount(row.store_payment_amount),
        ]),
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // 保存文件
      this.saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), { type: "application/octet-stream", headers: { "Content-Disposition": "attachment;filename=" + ws_name + ".xlsx" } });
    
        },

        // 辅助函数，将二进制字符串转换为 ArrayBuffer
 s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
},

// 辅助函数，用于在客户端保存文件
 saveAs(blob, options) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = options.headers["Content-Disposition"].split(';')[1].split('=')[1].replace(/"/g, "");
  a.click();
  window.URL.revokeObjectURL(url);
},
//   ------------------------------------------------------------
        //   获取third数据
        async _getthirdtable() {
            const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutReceipt", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: this.pageinfothird.pageIndex,
              pageSize: this.pageinfothird.pageSize,
            }
          });
            if (res.code !== 1) return this.$message.error(res.message);
            this.tableDatathird = res.data;
            this.pageinfothird = res.pageInfo;
          },

          //   third表格分页
          thirdhandleCurrentChange(val) {
            this.pageinfothird.pageIndex = val;
            this._getthirdtable();
           },        
           //  导出数据
           async thirdexportToExcel(){
            const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutReceipt", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: 1,
              pageSize: this.pageinfothird.total,

            }
            });
            if (res.code !== 1) return this.$message.error(res.message);
            let datathird = res.data.filter(row => row.exchange_hide_flag !== '1'); // 过滤掉需要隐藏的数据
            let date = moment().format("MM-DD HH:mm");

            const wsName =  `${this.storename}-tab3-${date}`;
      const wsData = [
        [
          "流水号",
          "流水金额",
          "流水币种",
          "收款单号",
          "收款单店名",
          "收款单客户名字",
          "收款单金额",
          "对手的交易单号",
          "对手的交易单类型",
          "对手的交易单状态",
          "是否隐藏",
          "对手的交易单店铺",
          "对手的交易单详情金额",
          "对手的交易单客户名字"
        ],
        ...datathird.map(row => [
          row.store_account_flow_id,
          this.formatAmount(row.store_account_flow_amount),
          row.store_account_flow_currency_name,
          row.store_receipt_no,
          row.store_receipt_store_name,
          `${row.store_receipt_archives_name} (${row.store_receipt_archives_no})`,

          this.formatAmount(row.store_receipt_amount),
          row.store_exchange_no,
          row.exchange_type_name,
          row.exchange_status_name,
          row.exchange_hide_flag == 1 ? "是" : "否",
          row.exchange_store_name,
          this.formatAmount(row.store_exchange_detail_amount),
          `${row.store_exchange_archives_name} (${row.store_exchange_archives_no})`
        ])
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, wsName);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // 保存文件
      this.saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), {
        type: "application/octet-stream",
        headers: { "Content-Disposition": `attachment;filename=${wsName}.xlsx` }
      });

        },

    // ------------------------------------------------------------
        //    获取fourtabel数据
        async _getfourthtable() {
            const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutStorePay", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: this.pageinfofourth.pageIndex,
              pageSize: this.pageinfofourth.pageSize,
            }
          });
            if (res.code !== 1) return this.$message.error(res.message);
            this.tableDatafourth = res.data;
            this.pageinfofourth = res.pageInfo;
        },
        //   four表格分页
        fourthhandleCurrentChange(val) {
            this.pageinfofourth.pageIndex = val;
            this._getfourthtable();
         },
         //  导出数据
       async  fourthexportToExcel(){
          const {data:res} = await this.$http.get("/StoreAccount/GetStore2StoreAccountFlowOutStorePay", {
            params: {
              st: this.formLabelAlign.st,
              et: this.formLabelAlign.et,
              storeID: this.formLabelAlign.storeID,
              currencyID: this.formLabelAlign.currencyID,
              differentStore: this.formLabelAlign.differentStore,
              pageIndex: 1,
              pageSize: this.pageinfofourth.total,
            }
          });
          if (res.code !== 1) return this.$message.error(res.message);
          let datafour = res.data.filter(row => row.exchange_hide_flag !== '1'); // 过滤掉需要隐藏的数据
          let date = moment().format("MM-DD HH:mm");

          const wsName = `${this.storename}-tab4-${date}`
          console.log(wsName);
      const wsData = [
        [
          "流水编号",
          "流水金额",
          "流水币种",
          "付款单号",
          "付款单店铺",
          "付款金额",
          "对手的交易单号",
          "对手的交易单店铺",
          "对手的交易单类型",
          "对手的交易单状态",
          "是否隐藏",
          "对手的交易单详情金额",
          "对手的交易单客户名字"
        ],
        ...datafour.map(row => [
          row.store_account_flow_id,
          this.formatAmount(row.store_account_flow_amount),
          row.store_account_flow_currency_name,
          row.store_payment_no,
          row.store_payment_store_name,
          this.formatAmount(row.store_payment_amount),
          row.store_exchange_no,
          row.exchange_store_name,
          row.exchange_type_name,
          row.exchange_status_name,
          row.exchange_hide_flag == 1 ? "是" : "否",
          this.formatAmount(row.store_exchange_detail_amount),
          `${row.store_exchange_archives_name} (${row.store_exchange_archives_no})`
        ])
      ];

      // 创建一个新的工作表
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // 创建一个新的工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, wsName);

      // 写入字符串
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // 保存文件
      this.saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), {
        type: "application/octet-stream",
        headers: { "Content-Disposition": `attachment;filename=${wsName}.xlsx` }
      });

         },
    },
}
</script>
<style lang="less" scoped></style>